#register-container{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    top: 0;
    left: 0;
    z-index: 9999;
    #register{
        width: 900px;
        padding: 50px 50px 0;
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #00a3e3;
        box-shadow: 10px 10px 5px -4px rgba(204,204,204,1);
        background: #e6f6fb;
        @media (max-width: 910px){
            width: 80%;
            padding-top: 0;
        }
        @media (max-width: 764px){
            top: 40%;
        }
        @media (max-width: 575px){
            top: 2%;
            transform: translate(-50%, 0%);
        }
        .pencil{
            position: absolute;
            top: -80px;
            left: 50%;
            transform: translateX(-50%);
            @media (max-width: 910px){
                display: none;
            }
        }
        h2{
            color: #00a3e3;
            float: left;
            font-size: 40px;
            width: 100%;
            text-align: center;
            font-weight: normal;
            margin: 20px 0;
            @media (max-width: 910px){
                font-size: 25px;
                margin: 10px 0;
            }
        }
        p.text{
            float: left;
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-family: arial;
            padding: 0px 0 25px;
            margin-top: 0;
            border-bottom: 3px solid black;
            margin-bottom: 25px;
            @media (max-width:424px){
                display: none;
            }
        }
        .fields{
            float: left;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .field{
                width:32%;
                text-align: center;
                @media (max-width: 575px){
                    width: 100%;
                }
                img{
                    @media (max-width: 910px){
                        display: none;
                    }
                }
                input{
                    float: left;
                    width: 100%;
                    height: 50px;
                    border: 1px solid $blue;
                    margin-top: 15px;
                    font-size: 18px;
                    padding: 0 15px;
                    color: $blue;
                    @media (max-width: 575px){
                        margin-top: 5px;
                        font-size: 16px;
                        height: 30px;
                    }
                    &::placeholder{
                        color: $blue;
                    }
                }
            }
        }
        .button{
            float: left;
            width: 100%;
            text-align: center;
            margin-bottom: 25px;
            margin-top: 25px;
            .btn{
                @extend %btn;
                @media (max-width: 910px){
                    font-size: 16px;
                }
            }
        }
    }
}