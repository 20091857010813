.modal-container{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    top: 0;
    left: 0;
    z-index: 9999;
    .modal-inner{
        width: 400px;
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid #00a3e3;
        box-shadow: 10px 10px 5px -4px rgba(204,204,204,1);
        background: #e6f6fb;
        &.comments-modal{ 
            width: 600px;
            h2{
                margin-top: 43px;
                margin-bottom: 10px;
            }
        }
        .pencil-big{
            position: absolute;
            top: -68px;
            left: 50%;
            transform: translateX(-50%);
            height: 125px;
        }
        h2{
            font-size: 35px;
            color: $blue;
            text-align: center;
            font-weight: normal;
        }
        .comment-img-container{
            float: left;
            width: 100%;
            text-align: center;
            #comment-image{
                width: 100px;
                margin: 0 auto;
            }
        }
        .textarea{
            float: left;
            width: 100%;
            margin-top: 15px;
            margin-bottom: 25px;
            textarea{
                float: left;
                width: 100%;
                height: 100px;
                padding: 10px;
                font-size: 16px;
            }
        }
        p.text{
            float: left;
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-family: arial;
            padding: 0px 0 40px;
            margin-top: 0;
        }
        .social{
            float: left;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            a{
                display: inline-block;
                img{
                    width: 25px;
                }
            }
        }
        .button{
            float: left;
            width: 100%;
            text-align: center;
            .btn{
                @extend %btn;
            }
        }
    }
}