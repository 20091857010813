.container{
    @extend %container_;
    &.games_{
        max-width: 750px;
        padding: 0 50px;
    }
    .black-intro {
        font-size: 2.4rem;
        color: $dark;
        margin-top: 50px;
        font-family: Ubuntu;
        margin-bottom: 50px;
        float: left;
        width: 100%;
        text-align: center;
        @media (max-width: 767px){
            margin-top: 20px;
            font-size: 1.8px;
        }
        ul,
        ol{
            li{
                text-align: left;
                font-size: 16px;
                margin-bottom: 10px;
                line-height: 20px;
            }
        }
    }
    .games{
        float: left;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .game{
            width: 31%;
            text-align: center;
            @media (max-width:575px){
                width: 100%;
            }
            &.disabled{
                img{
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                    opacity: 0.4;
                }
            }
            .icon{
                float: left;
                width: 100%;
                margin-bottom: 25px;
                img{
                    width: 100%;
                    max-width: 150px;
                }
            }
            .title{
                float: left;
                width: 100%;
                a{
                    font-size: 5.6rem;
                    float: left;
                    width: 100%;
                    color: $blue;
                    line-height: normal;
                    text-decoration: none;
                }
            }
            .subtitle{
                float: left;
                width: 100%;
                color: $blue;
                margin-bottom: 35px;
                font-size: 1.6rem;
                line-height: normal;
            }
            .text{
                float: left;
                width: 100%;
                font-family: Arial, Helvetica, sans-serif;
                font-size: 1.5rem;
                margin-bottom: 36px;
                height: 100px;
                p{
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
            }
            .button{
                float: left;
                width: 100%;
                &.videobtn{
                    margin-bottom: 25px;
                    a{
                        background: white;
                        color: $blue;
                        border: 2px solid $blue;
                        width: 80%;
                        font-size: 18px;
                        @media (max-width: 767px){
                            font-size: 15px;
                            width: 100%;
                        }
                        @media (max-width:575px){
                            width: 80%;
                        }
                    }
                }
                a{
                    @extend %btn;
                    &.disabled{
                        opacity: 0.5;
                        background: #999;
                        &:hover{
                            background: #333;
                        }
                    }
                }
                &.btnlanding{
                    a{
                        width: 80%;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.video{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    .vcontainer{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 1000px;
        video{
            width: 100%;
        }
    }
}

.instructions_btn{
    float: left;
    width: 100%;
    margin-top: 15px;
    a{
        @extend %btn;
        background: white;
        color: $blue;
        border: 2px solid $blue;
        padding: 15px 10px;
        width: auto;
        font-size: 18px;
        &:hover{
            color: white !important;
            background: $blue;
        }
    }
}
#neighborhood{
    float: left;
    width: 200px;
    border: 2px solid #00a3e3;
    height: 40px;
    font-size: 16px;
    color: #424242;
    padding: 0 15px;
}