// Mixins
%container_ {
    max-width: 1220px;
    margin: 0 auto;
    width: 100%;
    display: block;
    height: 81px;
    padding: 10px 20px;
    position: relative;
}
%btn{
    color: white;
    font-family: Ubuntu;
    font-size: 2.1rem;
    border-radius: 15px;
    padding: 15px;
    text-decoration: none;
    transition: all 0.5s;
    background: $blue;
    display: inline-block;
    text-align: center;
    &:hover{
        background: darken($blue,15%);
    }
}