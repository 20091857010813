.main-icon{
    margin: 0 auto;
    max-width: 850px; 
    padding: 0 20px;
    text-align:center;
    top: -15px;
    position: relative;
    .title{
        font-size: 46px;
        color: $blue;
        text-align: center;
    }
    .black-intro{
        font-size: 2.0rem;
        margin-top: 0;
    }
}
.questions {
    width: 1000px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
    .question{
        float: left;
        width: 100%;
        border-top: 3px solid $dark;
        padding-top: 25px;
        margin-top: 25px;
        .q{
            float: left;
            width: 100%;
            margin-bottom: 20px;
            font-size: 2.0rem;
        }
        ul{
            float: left;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            li{
                float: left;
                width: calc(20% - 50px);
                margin: 0px 50px 20px 0;
                max-width: 145px;
                text-align: center;
                @media (max-width: 767px){
                    width: calc(33.33% - 50px);
                }
                @media (max-width: 575px){
                    width: calc(50% - 50px);
                }
                label{
                    float: left;
                    width: 100%;
                    position: relative;
                    cursor: pointer;
                    input{
                        display: none;
                    }
                    img{
                        display: inline-block;
                        margin: 0 auto;
                        float: none;
                        &.on{
                            display: none;
                        }
                    }
                    &.active{
                        img{
                            display: none;
                            &.on{
                                display: block !important;
                            }
                        }
                    }
                    span{
                        float: left;
                        text-align: center;
                        width: 100%;
                        font-size: 16px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
.button-chat{
    float: left;
    width: 100%;
    border-top: 3px solid $dark;
    padding-top: 25px;
    margin-top: 25px;
}