//Main container
.container{
    @extend %container_;
    .main-intro{
        font-size: 3.0rem;
        line-height: 4.2rem;
        color: $blue;
        margin-top: 50px;
        font-family: Ubuntu;
        text-align: center;
        padding: 0 45px;
        margin-bottom: 50px;
        float: left;
        width: 100%;
        @media (max-width: 767px){
            margin-top: 0px;
            font-size: 2.2rem;
            line-height: 3rem;
        }
        @media (max-width: 575px){
            margin-top: 20px;
            font-size: 1.6rem;
            line-height: normal;
            padding: 0;
        }
    }
    .button{
        float: left;
        width: 100%;
        text-align: center;
        margin-bottom: 100px;
        .btn{
            @extend %btn;
        }
    }
    .tool-video{
        float: left;
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
        padding: 0 10%;
        @media (max-width:425px){
            padding: 0;
        }
        video{
            float: left;
            width: 100%;
        }
        .inner { 
            position: relative; 
            padding-bottom: 56.25%; 
            height: 0; 
            overflow: hidden; 
            max-width: 850px;
            // max-width: 90%;
            margin: 0 auto;
            iframe, 
            object,
            embed { 
                position: absolute; 
                top: 0; 
                left: 0; 
                width: 100%; 
                height: 100%; 
            }
        } 
    }
}