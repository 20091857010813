.pics {
    float: left;
    width: 100%;
    padding: 0 50px 40px;
    margin-bottom: 25px;
    border-bottom: 3px solid $dark;
    @media (max-width:575px){
        padding: 0 0 10px;
    }
    .pic{
        float: left;
        width: 100%;
        display: flex;
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s;
        &.active{
            max-height: 1000px;
        }
        .thumb{
            position: relative;
            float: left;
            width: 9%;
            background: transparent;
            border: none;
            cursor: pointer;
            img{
                max-width: 100%;
                position:absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
                filter: gray; /* IE6-9 */
                -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
                transition: all 0.5s;
            }
            &:hover{
                img{
                    filter: none;
                    -webkit-filter: grayscale(0%);
                }
            }
        }
        .image{
            float: left;
            width: 82%;
            padding: 0 25px;
            img{
                width: 100%;
            }
            .caption {
                float: left;
                padding: 10px 0;
                font-size: 14px;
            }
            .quantity {
                float: right;
                font-size: 14px;
                padding: 10px 0;
            }
        }
    }
}

.your{
    float: left;
    width: 100%;
    h2{
        font-size: 35px;
        color: $blue;
    }
    ul{
        float: left;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        li{
            width: 24%;
            position: relative;
            float: left;
            margin-right: 1.33%;
            margin-bottom: 1.33%;
            &:nth-child(4n){
                margin-right: 0;
            }
            .main{
                width: 100%;
            }
            .pencil {
                left: 10px;
                position: absolute;
                top: 10px;
                padding: 0;
                background: transparent;
                border: none;
            }
            .x {
                right: 10px;
                position: absolute;
                top: 10px;
                padding: 0;
                background: transparent;
                border: none;
            }
            .text {
                float: left;
                width: 100%;
                margin-top: 5px;
            }
            textarea{
                display: none;
            }
        }
    }
}
.pics-button{
    margin-top: 100px;
}