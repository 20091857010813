.map-container{
    float: left;
    width: 100%;
    position: relative;
    .header{
        float: left;
        width: 100%;
        background: $dark;
        display: none;
    }
    &.active{
        .header{
            display: block;
        }
        .mapC{
            video{
                display: none;
            }
        }
    }
    .text{
        float: left;
        color: white;
        width: 0;
        overflow: hidden;
        &.active{
            width: calc(100% - 350px);
            @media (max-width: 767px){
                width: calc(100% - 205px);
            }
        }
        .controls{
            margin-top: 10px;
            display: none;
            @media (max-width: 767px){
                margin-top: 5px;
            }
            .numbers{
                float: left;
                a{
                    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(100%);
                    border-radius: 100%;
                    cursor: pointer;
                    @media (max-width: 767px){
                        width: 30px;
                    }
                    &.active{
                        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                        filter: grayscale(0%);
                    }
                    &.filled{
                        -webkit-filter: brightness(130%); /* Safari 6.0 - 9.0 */
                        filter: brightness(130%);
                    }
                }
            }
            a{
                background: transparent;
                width: 50px;
                border: none;
                padding: 0;
                margin-right: 10px;
                float: left;
                margin: 0 10px 0 0;
                border: 2px solid white;
                border-radius: 100%;
                cursor: pointer;
                @media (max-width:767px){
                    width: 30px;
                    height: 30px;
                }
                &.clear-current-route{
                    display: none;
                    background: #E2211C;
                    color: white;
                    width: 50px;
                    height: 50px;
                    font-size: 30px;
                    font-weight: bold;
                    text-align: center;
                    padding: 7px;
                    height: 50px;
                }
                img{
                    width: 100%;
                }
            }
        }
    }
    .icons{
        float: left;
        width: 100%;
        .icon{
            float: left;
            width: 50%;
            &:nth-of-type(2){
                float: right;
                button{
                    float: right;
                }
                .numbers{
                    float: right;
                }
            }
        }
        button{
            background: white;
            border: none;
            padding: 0;
            border-radius: 100%;
            margin: 10px;
            cursor: pointer;
            float: left;
            @media (max-width:767px){
                margin: 5px;
            }
            img{
                @media (max-width:767px){
                    width: 30px;
                }
                @media (max-width: 445px){
                    width: 25px;
                }
            }
            &.active{
                background: $blue;
                &#i2{
                   background:#41ad49; 
                }
                &#i3{
                   background:#838684; 
                }
                &#i4{
                   background:#F18A50; 
                }
                &#i5{
                   background:#E2211C; 
                }
                img{
                    -webkit-filter: brightness(0) invert(1);
                    filter: brightness(0) invert(1);
                }
            }
        }
        .numbers{
            float: left;
            margin-top: 10px;
            @media (max-width: 767px){
                margin-top: 5px;
            }
            a{
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
                border-radius: 100%;
                cursor: pointer;
                &.active{
                    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                    filter: grayscale(0%);
                }
                &.filled{
                    -webkit-filter: brightness(130%); /* Safari 6.0 - 9.0 */
                    filter: brightness(115%) contrast(160%);
                }
                img{
                    @media (max-width: 767px){
                        width: 30px;
                    }
                    @media (max-width: 445px){
                        width: 25px;
                    }
                }
            }
        }
    }
    #undo{
        background: #E2211C;
        color: white;
        width: 35px;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        padding: 7px;
        height: 35px;
        border-radius: 100%;
        border: 2px solid white;
        position: absolute;
        top: 80px;
        left: 50px;
        z-index: 999;
    }
    #map{
        height: 650px;
        float: left;
        width: 100%;
        margin-bottom: 100px;
    }
    .mapC{
        position: relative;
        float: left;
        width: 100%;
        video{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 999999;
            width: 100%;
        }
    }
}