.result-sections {
    float: left;
    width: 100%;
    border-top: 5px solid black;
    padding: 30px 0;
    &.respondants{
        display: flex;
    }
    h2{
        color: $blue;
        font-size: 30px;
        font-weight: normal;
        margin-bottom: 0;
    }    
    p{
        float: left;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 45px;
        font-size: 2.0rem;
    }
    .responses{
        float: left;
        width: 100%;
        ul{
            float: left;
            width: 100%;
            li{
                float: left;
                width: 100%;
                font-size: 20px;
                margin-bottom: 10px;
            }
        }
    }
    .left{
        width: 50%;
        text-align: right;
        padding-right: 20px;
        .big{
            color: $blue;
            font-size: 150px;
            font-weight: bold;
            line-height: 93px;
        }
        .small{
            color: $blue;
            font-size: 30px;
            line-height: 35px;
            padding-top: 18px;
        }
    }
    .icons-respondants{
        width: 50%;
        text-align: left;
        padding-left: 20px;
        .row{
            min-height: 35px;
        } 
    }
    .icons{
        float: left;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .icon{
            float: left;
            margin-right: 25px;
            width: calc(20% - 50px);
            max-width: 145px;
            text-align: center;
            @media (max-width: 767px){
                width: calc(33.33% - 50px);
            }
            @media (max-width: 575px){
                width: calc(50% - 50px);
            }
            img{
                max-width: 100%;
            }
            .quantity{
                margin-top: 10px;
                float: left;
                width: 100%;
                font-size: 16px;
                text-align: center;
            }
        }
    }
    .icons_getting{
        float: left;
        width: 100%;
        img{
            width: 70px;
            margin-right: 0;
            filter: brightness(0%);
            float: left;
        }
        span{
            font-size: 34px;
            float: left;
            padding-top: 17px;
            font-weight: bold;
            padding-right: 20px;
            color: $blue;
        }
    }
    ul{
        float: left;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin:0;
        padding: 0;
        list-style: none;
        justify-content: space-between;
        li{
            width: 24%;
            img{
                float: left;
                width: 100%;
            }
            .likes{
                float: left; 
                width: 100%;
                margin-top: 5px;
                text-align: right;
                margin-bottom: 10px;
            }
        }
    }
}
#maplive,
#walking,
#bike,
#drive,
#bus,
#train{
    height: 650px;
    float: left;
    width: 100%;
    margin-bottom: 100px;
}

.marker-cluster{
    background-color: rgba(0, 163, 227,0.6) !important;
    div{
        background-color: rgba(0, 163, 227,0.6) !important;
        color: white;
    }
}