html {
    box-sizing: border-box;
    font-size: 10px;
}
*, *:before, *:after {
    box-sizing: inherit;
}
@import "partials/reboot"; 
@import "partials/colors"; 
@import "partials/mixins"; 
@import "partials/header"; 
@import "partials/home"; 
@import "partials/footer"; 
@import "partials/games"; 
@import "partials/register"; 
@import "partials/chat"; 
@import "partials/modal"; 
@import "partials/pics_game"; 
@import "partials/map_game"; 
@import "partials/results"; 

//Principal
body{
    padding-top: 130px;
    font-family: Ubuntu;
    color: $dark;
}

