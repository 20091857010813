//Footer
footer{
    float: left;
    width: 100%;
    background: $dark;
    padding: 10px 0 50px;
    .container{
        @extend %container_;
        height: auto;
        .left{
            color: $white;
            margin-left: 7px;
            font-family: Arial;
            font-size: 1.4rem;
            line-height: 2.5rem;
            width: 170px;
            float: left;
            a{
                color: $white;
                text-decoration: none;
                &:hover{
                    color: $blue;
                }
            }
        }
        .right{
            float: right;
            width: calc(100% - 200px);
            padding-top: 13px;
            .social{
                float: right;
                margin: 0;
                padding:0;
                list-style: none;
                li{
                    float: left; 
                    margin-right: 10px;
                    a{
                        border: 1px solid $white;
                        color: $white;
                        font-size: 1.6rem;
                        border-radius: 100%;
                        width: 32px;
                        height: 32px;
                        float: left;
                        text-align: center;
                        padding-top: 6px;
                        transition: all 0.5s;
                        &:hover{
                            color: white;
                        }
                    }
                }
            }
        }
    }
}