header{
    background: white;
    width: 100%;
    box-shadow: 0px 1px 5px 0px #b1b3b6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    .container{
        @extend %container_;
        .logo {
            float: left;
            width: 170px;
        }
        .legend{
            float: left;
            width: 155px;
            margin-left: 10px;
            font-size: 2.2rem;
            font-family: Ubuntu;
            line-height: normal;
            margin-top: 14px;
            @media (max-width: 399px){
                display: none;
            }
        }
        nav{
            float: right;
            margin-top: 2.6rem;
            .ham{
                float: right;
                font-size: 25px;
                color: $dark;
                display: none;
                @media (max-width:949px){
                    display: block;
                }
                &:hover,
                &.active{
                    color: $blue;
                }
            }
            ul{
                float: right;
                margin: 0;
                padding: 0;
                list-style: none;
                @media (max-width:949px){
                    position: absolute;
                    width: 100%;
                    right: 20px;
                    max-width: 320px;
                    top: 94px;
                    background: white;
                    max-height: 0;
                    overflow:hidden;
                    transition: all 0.5s;
                    &.active{
                        max-height: 500px;
                    }
                }
                li{
                    float: left;
                    margin-left: 30px;
                    @media (max-width:949px){
                        float: left;
                        margin-left: 0;
                        width: 100%;
                        padding: 13px 0;
                        border-bottom: 1px solid #ccc;
                    }
                    a{
                        float: left; 
                        color: $dark;
                        font-size: 2.1rem;
                        text-decoration: none;
                        font-family: Ubuntu;
                        @media (max-width:949px){
                            float: left;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

